import { faPen } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';

import styles from './Profile.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import request from '../../utils/request';
import Button from '../../components/Button';
import DropImage from '../../components/DropImage';
import { useGlobalContext } from '../../hooks/useGlobalContext';
import { useAuth } from '../../hooks/useAuth';
import images from '../../assets/images';
import NotFoundPage from '../NotFoundPage';
const cx = classNames.bind(styles);
function Profile() {
    const { user } = useAuth();
    let { email } = useParams();
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(null);
    const [isMyInfo, setIsMyInfo] = useState(false);
    const [error, setError] = useState(<div></div>);
    const { popupWarning, toastPromise } = useGlobalContext();
    const refAvatar = useRef();
    const nameRef = useRef();
    useEffect(() => {
        const info = email.split('!');
        if (user) {
            if (!info[1] || user.id === Number(info[1])) {
                setIsMyInfo(true);
            }
        }
        request
            .get('/get-profile', { params: { id: info[1], email: info[0].slice(1) } })
            .then((data) => {
                setUserInfo(data.data);
            })
            .catch((err) => {
                setError(<NotFoundPage></NotFoundPage>);
            });
    }, [email, user, navigate]);
    if (!userInfo) {
        return error;
    }

    return (
        <div className={cx('wraper')}>
            {/* <img className={cx('image-blur')} src={userInfo.banner || images.defaultBanner} alt=""></img> */}
            <div className={cx('banner')}>
                <img className={cx('banner-image')} src={userInfo.banner || images.defaultBanner} alt=""></img>
                {isMyInfo && (
                    <Button
                        onClick={() => {
                            popupWarning({
                                message: (
                                    <div className={cx('popup', 'image-banner')}>
                                        <h1>Chỉnh sửa ảnh banner</h1>
                                        <DropImage
                                            className={cx('image-banner')}
                                            url={userInfo.banner}
                                            ref={refAvatar}
                                        ></DropImage>
                                    </div>
                                ),
                                accecpt: () => {
                                    const toastDelete = toastPromise('Đang tải ảnh lên');
                                    refAvatar.current
                                        .uploadImage()
                                        .then((data) => {
                                            request.post('/update-profile', { banner: data.url });
                                            setUserInfo((prev) => {
                                                return { ...prev, banner: data.url };
                                            });
                                            toastDelete.success('Tải ảnh thành công !');
                                        })
                                        .catch(() => {
                                            toastDelete.error('Tải ảnh thất bại !');
                                        });
                                },
                            });
                        }}
                        className={cx('button-banner')}
                        icon={faPen}
                        primary
                    ></Button>
                )}
                <div className={cx('user')}>
                    <div className={cx('user-avatar')}>
                        <img className={cx('avatar')} src={userInfo.avatar || images.defaultAvatar} alt=""></img>
                        {isMyInfo && (
                            <Button
                                onClick={() => {
                                    popupWarning({
                                        message: (
                                            <div className={cx('popup')}>
                                                <h1>Chỉnh sửa avatar</h1>
                                                <DropImage
                                                    className={cx('image-avatar')}
                                                    url={userInfo.avatar}
                                                    ref={refAvatar}
                                                ></DropImage>
                                            </div>
                                        ),
                                        accecpt: () => {
                                            const toastDelete = toastPromise('Đang tải ảnh lên');
                                            refAvatar.current
                                                .uploadImage()
                                                .then((data) => {
                                                    request.post('/update-profile', { avatar: data.url });
                                                    setUserInfo((prev) => {
                                                        return { ...prev, avatar: data.url };
                                                    });
                                                    toastDelete.success('Tải ảnh thành công !');
                                                })
                                                .catch(() => {
                                                    toastDelete.error('Tải ảnh thất bại !');
                                                });
                                        },
                                    });
                                }}
                                className={cx('button')}
                                icon={faPen}
                                primary
                            ></Button>
                        )}
                    </div>
                    <div className={cx('user-name')}>
                        <span>{userInfo.name}</span>
                        {isMyInfo && (
                            <Button
                                onClick={() => {
                                    popupWarning({
                                        message: (
                                            <div className={cx('popup')}>
                                                <h1>Chỉnh sửa tên</h1>
                                                <input
                                                    ref={nameRef}
                                                    className={cx('input-name')}
                                                    placeholder="Vui lòng nhập tên mới"
                                                ></input>
                                            </div>
                                        ),
                                        accecpt: async () => {
                                            const toastDelete = toastPromise('Đang chỉnh sửa...');
                                            const name = nameRef.current.value;
                                            try {
                                                await request.post('/update-profile', {
                                                    name: name,
                                                });
                                                setUserInfo((prev) => {
                                                    return { ...prev, name: name };
                                                });

                                                toastDelete.success('Chỉnh sửa thành công !');
                                            } catch (error) {
                                                toastDelete.error('Chỉnh sửa thất bại !');
                                            }
                                        },
                                    });
                                }}
                                className={cx('btn-name')}
                                icon={faPen}
                                primary
                            ></Button>
                        )}
                    </div>
                </div>
            </div>
            <div className={cx('action')}></div>
            <div className={cx('content')}>Đây là trang thông tin tải khoản</div>
        </div>
    );
}
export default Profile;
