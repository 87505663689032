const routes = {
    home: '/',
    login: '/login',
    register: '/register',
    verifyEmail: '/verify-email',
    exam: '/example',
    editExample(params) {
        return params ? `/myexam/${params}/edit` : '/myexam/:subject/edit';
    },
    test: '/test',
    abc: '/abc',
    conTest(params) {
        return params ? `/exam/${params}` : '/exam/:subject';
    },
    myExam: '/myexam',
    createExample: '/myexam/create-example',
    profile(params) {
        return params ? `/profile/@${params}` : '/profile/:email';
    },
    share: '/share/:id',
    favorite: '/my-favorite',
    changePassword: '/change-password',

    admin: '/admin',
};
export default routes;
