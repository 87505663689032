import Account from '../layout/Account';
import HomeLayout from '../layout/Home';

import HomePage from '../pages/Home';
import ExamQuestions from '../pages/ExamQuestions';
import MyExample from '../pages/MyExample';
import Contest from '../pages/Contest';
import Login from '../pages/Login';
import CreateExample from '../pages/CreateExample';
import Register from '../pages/Register';
import TestComponent from '../pages/TestComponent';
import VerifyEmail from '../pages/VerifyEmail';
import { routes as routesConfig } from '../config';
import EditExample from '../pages/EditExample';
import Profile from '../pages/Profile';
import ShareExample from '../pages/ShareExample';
import MyFavorite from '../pages/MyFavorite';
import ChangePassword from '../pages/ChangePassword';
import HomeAdmin from '../pages/admins/HomeAdmin';
const publicRoutes = [
    { path: routesConfig.home, component: HomePage, layout: HomeLayout },
    { path: routesConfig.login, component: Login, layout: Account },
    { path: routesConfig.register, component: Register, layout: Account },
    { path: routesConfig.verifyEmail, component: VerifyEmail, layout: Account },
    { path: routesConfig.exam, component: ExamQuestions },
    { path: routesConfig.test, component: TestComponent },
    { path: routesConfig.share, component: ShareExample },
];

const privateRoutes = [
    { path: routesConfig.conTest(), component: Contest, props: { sideBarMini: true } },
    { path: routesConfig.myExam, component: MyExample },
    { path: routesConfig.editExample(), component: EditExample, props: { sideBarMini: true } },
    { path: routesConfig.createExample, component: CreateExample, props: { sideBarMini: true } },
    { path: routesConfig.profile(), layout: HomeLayout, component: Profile, props: { sideBarMini: true } },
    { path: routesConfig.changePassword, component: ChangePassword, layout: Account },
    { path: routesConfig.favorite, component: MyFavorite },
];

const adminRoutes = [{ path: routesConfig.admin, component: HomeAdmin, layout: null }];

export { publicRoutes, privateRoutes, adminRoutes };
