import classNames from 'classnames/bind';
import { useCallback, useEffect, useState } from 'react';

import styles from './MyFavorite.module.scss';
import CardExample from '../../components/CardExample';
import request from '../../utils/request';
import Input from '../../components/Input';
import Button from '../../components/Button';
const cx = classNames.bind(styles);

function MyFavorite() {
    const [page, setPage] = useState({});
    const [listMyExample, setListMyExample] = useState([]);
    const [search, setSearch] = useState('');
    const getExample = useCallback(
        (url = 'get-favorite') => {
            request
                .get(url, { params: { query: search } })
                .then((example) => {
                    handleChangePage(example.data);
                })
                .catch(() => {});
        },
        [search],
    );
    const handleChangePage = (example) => {
        const { prev_page_url, next_page_url, links } = example;
        links.shift();
        links.pop();
        window.scrollTo(0, 0);
        setPage({ prevPageUrl: prev_page_url, nextPageUrl: next_page_url, groupPage: links });
        setListMyExample(example.data);
    };
    useEffect(() => {
        getExample();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);
    return (
        <div className={cx('wraper')}>
            <div className={cx('filter')}>
                <Input
                    title="Tìm kiếm"
                    handleDebounce={(value) => {
                        setSearch(value);
                    }}
                ></Input>
                <div className={cx('tag')}></div>
            </div>
            <div className={cx('content')}>
                {listMyExample.map((example, index) => {
                    return <CardExample getExample={getExample} key={index} example={example}></CardExample>;
                })}
                <div className={cx('page')}>
                    <Button
                        primary
                        disable={!page.prevPageUrl}
                        onClick={() => {
                            getExample(page.prevPageUrl);
                        }}
                    >
                        Trang trước
                    </Button>
                    <div className={cx('group-page')}>
                        {page.groupPage?.map((page, index) => {
                            return (
                                <Button
                                    disable={page.active || page.label === '...'}
                                    primary
                                    key={index}
                                    onClick={() => {
                                        getExample(page.url);
                                    }}
                                >
                                    {page.label}
                                </Button>
                            );
                        })}
                    </div>
                    <Button
                        primary
                        disable={!page.nextPageUrl}
                        onClick={() => {
                            getExample(page.nextPageUrl);
                        }}
                    >
                        Trang tiếp
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default MyFavorite;
