import classNames from 'classnames/bind';
import { faCircleUser, faSquarePlus } from '@fortawesome/free-regular-svg-icons';
import Tippy from '@tippyjs/react/headless';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    faBars,
    faCircleHalfStroke,
    faCircleInfo,
    faEllipsisVertical,
    faLock,
    faRegistered,
    faRightFromBracket,
    faSearch,
    faUserTie,
} from '@fortawesome/free-solid-svg-icons';

import styles from './Header.module.scss';
import images from '../../../assets/images';
import Button from '../../../components/Button';
import NavList from '../NavList';
import { useAuth } from '../../../hooks/useAuth';
import { routes } from '../../../config';

const cx = classNames.bind(styles);

function Header({ navBar, onToggleSideBar }) {
    const [visibleModel, setVisibleModel] = useState(false);
    const searchRef = useRef();
    const btnSearch = useRef();

    const { user, logout, auth } = useAuth();
    const navigate = useNavigate();
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            btnSearch.current.click();
        }
    };

    const [menus, setMenus] = useState([
        {
            title: 'Chuyển nền',
            icon: faCircleHalfStroke,
            event: {
                onClick() {
                    let classList = document.body.classList;
                    classList.toggle('theme-dark');
                },
            },
        },
        {
            title: 'Thông tin tài khoản',
            icon: faCircleInfo,
            event: {
                to: routes.profile('this'),
            },
        },
        {
            title: 'Đổi mật khẩu',
            icon: faLock,
            event: {
                to: routes.changePassword,
            },
        },
        {
            title: 'Đăng xuất',
            icon: faRightFromBracket,
            event: {
                onClick: logout,
            },
        },
    ]);

    useEffect(() => {
        auth(false);

        if (user && user.role === 'admin') {
            setMenus((prev) => {
                const newMenus = [
                    {
                        title: 'Trang quản trị',
                        icon: faUserTie,
                        event: {
                            to: routes.admin,
                        },
                    },
                    ...prev,
                ];
                return newMenus;
            });
        }
    }, [auth, user]);

    return (
        <div className={cx('wraper')}>
            <div className={cx('header-logo')}>
                <Button className={cx('toogle-menu')} onClick={onToggleSideBar} large icon={faBars}></Button>
                <img src={images.logo} alt="Logo"></img>
                <Link to={routes.home}>
                    <h1 className={cx('header-title')}>{process.env.REACT_APP_NAME}</h1>
                </Link>
            </div>
            <div className={cx('search')}>
                <input ref={searchRef} onKeyDown={(e) => handleKeyDown(e)} placeholder="Tìm kiếm đề thi"></input>
                <Button
                    ref={btnSearch}
                    onClick={() => {
                        navigate(routes.exam, { state: { search: searchRef.current.value } });
                    }}
                    className={cx('icon')}
                    icon={faSearch}
                ></Button>
            </div>
            <div className={cx('action')}>
                {navBar && (
                    <div className={cx('nav-bar')}>
                        <Button className={cx('active')} white to={routes.home}>
                            Trang chủ
                        </Button>
                        <Button white to={routes.exam}>
                            Đề thi
                        </Button>
                        <Button to={routes.myExam} white>
                            Đề thi của tôi
                        </Button>
                    </div>
                )}
                {user ? (
                    <>
                        <Button to={routes.createExample} className={cx('create-exam')} leftIcon={faSquarePlus} primary>
                            Tạo đề thi
                        </Button>
                        <Tippy
                            visible={visibleModel}
                            onClickOutside={() => setVisibleModel(false)}
                            offset={[-5, 2]}
                            interactive
                            render={(attrs) => (
                                <div className={cx('menu-box')} tabIndex="-1" {...attrs}>
                                    <NavList menus={menus} />
                                </div>
                            )}
                        >
                            <div className={cx('account')} onClick={() => setVisibleModel(!visibleModel)}>
                                <img
                                    className={cx('avatar')}
                                    src={user.avatar || images.defaultAvatar}
                                    alt="Ảnh đại diện"
                                ></img>
                                <div className={cx('account_info')}>
                                    <h3 className={cx('name')}>{user.name}</h3>
                                    <h3 className={cx('role')}>{user.role.toUpperCase()}</h3>
                                </div>
                                <Button className={cx('menu')} leftIcon={faEllipsisVertical}></Button>
                            </div>
                        </Tippy>
                    </>
                ) : (
                    <>
                        <Button className={cx('register')} to={routes.register} leftIcon={faRegistered} primary>
                            Đăng ký
                        </Button>
                        <Button className={cx('login')} to={routes.login} leftIcon={faCircleUser} primary>
                            Đăng Nhập
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
}

export default Header;
