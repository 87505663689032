import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../useLocalStorage';
import request from '../../utils/request';
import { useGlobalContext } from '../useGlobalContext';
import { routes } from '../../config';

const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage('user', null);
    const navigate = useNavigate();
    const { toastPromise } = useGlobalContext();
    // call this function when you want to authenticate the user
    const login = async (info) => {
        const toastLogin = toastPromise('Đang đăng nhập...');
        try {
            const { data } = await request.post('login', info);
            await setUser(data);
            toastLogin.success('Đăng nhập thành công');
            navigate(routes.exam);
        } catch (error) {
            toastLogin.error(error.response ? error.response.data.message : error);
            navigate(routes.login);
            return true;
        }
    };

    // call this function to sign out logged in user
    const logout = async () => {
        const toastLogout = toastPromise('Đang đăng xuất...');
        try {
            await request.get('logout');
            setUser(null);
            toastLogout.success('Đã đăng xuất thành công !');
            navigate(routes.home, { replace: true });
        } catch (error) {
            toastLogout.error('Đăng xuất thất bại !');
        }
    };
    const auth = async (redirect = true) => {
        try {
            const { data } = await request.get('auth');

            if (JSON.stringify(user) !== JSON.stringify(data)) {
                await setUser(data);
            }
            return true;
        } catch (error) {
            setUser(null);
            if (redirect) {
                navigate(routes.login, { replace: true });
            }
            return false;
        }
    };
    const value = useMemo(
        () => ({
            user,
            login,
            logout,
            auth,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user],
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
